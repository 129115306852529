<template>
    <div class="empty-container">
        <div class="image">
            <img src="../assets/img/folder-empty.svg" alt="not fond">
        </div>
        <div class="text-block">
            {{$t('Empty data')}}
        </div>
    </div>
</template>

<script>
export default {
    name: "Empty"
}
</script>

<style scoped lang="scss">
    .empty-container{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .image{
            img{
                width: 136px;
            }
        }

        .text-block{
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            color: #141213;
            margin-top: 40px;
        }
    }
</style>
