<template>
    <div class="form-group">
        <div class="form-group__searchWrapper">
            <input
                v-model="val"
                class="form-control form-group__input"
                :placeholder="placeholder"
                type="text"
                @input="$emit('input', { value: $event.target.value, searchBy })"
                @blur="blur($event.target.value, searchBy)"
                @keyup.enter="blur(val, searchBy)"
            />
            <img v-if="!val" class="icon" src="@/assets/img/search.svg" alt="svg"/>
            <button v-if="val" class="clear-search" @click="clearInput(val, searchBy)"></button>
        </div>
    </div>
</template>

<script>
export default {
    name: "InputComponent",
    props: {
        value: {
            required: false,
        },
        cleared: {
            required: false,
            default: false
        },
        searchBy: {
            required: false,
            type: String,
            default: ''
        },
        routeValue: {
            required: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        isBlurSearch: {
            default: false,
            required: false,
            type: Boolean
        }
    },
    data() {
        return {
            val: this.setValue()
        }
    },
    methods: {
        blur(v, by) {
            if (this.cleared) {
                this.val = ''
            }
            if (this.isBlurSearch) {
                this.$emit('change', v, by)
            }
        },

        setValue() {
            if (this.routeValue) {
                if (this.$route.query[this.routeValue]) {
                    return this.$route.query[this.routeValue]
                }
                return this.value
            }
            return this.value
        },
        clearInput(val, searchBy){
            this.val = ''
            this.$emit('change', this.val, searchBy)
        }
    }
};
</script>

<style lang="scss" scoped>
.form-group {
    &__input {
        border: solid 1px $color_lightgray;
        border-radius: 2px;
        color: $color_black;
        background-color: white;
        padding: 10px 15px;
        font-size: 15px;
        font-family: $ff-primary;
        width: 100%;
        box-sizing: border-box;

        &::placeholder {
            color: $color_black;
        }
    }

    &__searchWrapper {
        position: relative;

        .icon {
            position: absolute;
            right: 16px;
            top: 50%;
            height: 18px;
            transform: translate(0, -50%);
        }
    }

    .clear-search{
        fill: transparent;
        background: url('~@/assets/img/close.svg');
        background-position: 75% center;
        background-repeat: no-repeat;
        width: 20px;
        margin-right: 0;
        border: none;
        height: 20px;
        position: absolute;
        right: 12px;
        top: 11px;
    }
}
</style>
